import React, { useState } from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <header className="flex items-center justify-between">
      <Link to="/">
        <h1>Yuta's Blog</h1>
      </Link>
      <Link to="/about/" onClick={toggleMenu}>
        About
      </Link>
    </header>
  )
}

export default Navigation
